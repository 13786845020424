<template>
  <div class="main-container">
    <v-overlay v-if="loading || !businessStore" class="loading-center">
      <Lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-container
      fluid
      v-if="businessStore && !loading"
      class="screen-width pt-0 pt-md-4"
    >
      <v-row>
        <v-col class="pa-0">
          <v-hover>
            <div class="button-wrapper">
              <v-btn
                color="white"
                absolute
                top
                right
                fab
                :small="$vuetify.breakpoint.mdAndDown"
                elevation="2"
                @click="$router.back()"
              >
                <v-icon color="primary">fas fa-chevron-left</v-icon>
              </v-btn>
            </div>
          </v-hover>
          <img
            :src="businessStore.cover.original"
            :lazy-src="businessStore.cover.loading"
            class="grey lighten-2 cover-up rounded"
          />
          <v-img
            :src="businessStore.logo.original"
            :lazy-src="businessStore.logo.loading"
            aspect-ratio="1"
            class="grey lighten-2 logo-up"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            id="businessName"
            class="text-h4 text-md-h3 mt-n0 mt-md-n8 text-center text--primary"
          >
            {{ businessStore.shortName }}
          </div>
        </v-col>
      </v-row>
      <ProductLoop
        :query="$store.state.search"
        :limit="limit"
        :menuId="menuId"
        :showChips="false"
        :menuTitleHeight="7.5"
        menuTitleClass="menu-title-products"
      />
    </v-container>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import ProductLoop from "@/components/ProductLoop.vue";
import { mapState } from "vuex";
export default {
  name: "Products",
  components: {
    Lottie,
    ProductLoop,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      menuId: this.$route.params.menuId,
      limit: 250,
    };
  },
  mounted() {
    if (this.businessStore == null) {
      this.$router.push({ name: "Home", params: { error: true } });
    }
    this.loading = false;
    document.title = `Ocho | ${this.businessStore.shortName} | Productos`;
    this.$store.state.visibleSearch = true;
    this.$store.state.search = "";
  },
  computed: {
    ...mapState(["businessStore"]),
  },
};
</script>

<style scoped>
.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -90px;
  margin-left: 20px;
  transition: all 0.3s ease-out;
  max-width: 130px;
  max-height: 130px;
}
.logo-up:hover {
  border: 2px dashed #f06f3d;
}
@media only screen and (max-width: 600px) {
  .logo-up {
    max-width: 80px;
    max-height: 80px;
    margin-top: -65px;
  }
}
.cover-up {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}
.cover-up:hover {
  border: 2px dashed #f06f3d;
}
@media only screen and (max-width: 600px) {
  .cover-up {
    max-height: 150px;
  }
}
.button-wrapper {
  position: absolute;
  margin-top: 3em;
  margin-left: 7.5em;
  opacity: 0.7;
}
@media only screen and (max-width: 600px) {
  .button-wrapper {
    margin-top: 2em;
    margin-left: 5.5em;
  }
}
</style>
